import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Button, Typography } from '@mui/material';
import { Buffer } from 'buffer';
import AuthContext from 'context/AuthContext';
import { useContext,  useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import CustomFileUpload from 'components/CustomFileUpload/CustomFileUpload';
import Loader from 'components/Loader/Loader';

import classes from '../../styles/globalStyle.module.scss';
import {
  generateFileMD5,
  uploadDocumentByPreSignedURL,
  uploadDocumentManually,
  uploadXlDocumentManually,
} from './uploadService';

const UploadBulkRequest = () => {
  const [loader, setLoader] = useState(false);
  const { username } = useContext(AuthContext);
  const schema = yup.object().shape({
    uploadFile: yup.mixed(),
  });
  const [postMsg, setPostMsg] = useState('');

  const handleUpload = async (data) => {
    const { uploadFile: file } = data;
    setLoader(true);
    const { name, size } = file;

    let format = 'csv';
    if (size > 3145728) {
      const fileMD5 = await generateFileMD5(file);
      const fileMd5Base64 = Buffer.from(fileMD5, 'hex').toString('base64');
      const payload = {
        format,
        name,
        size,
        contentMD5: fileMd5Base64,
        createdBy: username,
      };
      const resp = await uploadXlDocumentManually(payload, setLoader, setPostMsg);
      if (resp) {
        setLoader(true);
        const preSignedUrl = resp.preSignedUrl ? resp.preSignedUrl : null;
        await uploadDocumentByPreSignedURL(
          preSignedUrl,
          file,
          fileMd5Base64,
          setLoader,
          setPostMsg
        );
        setValue('uploadFile', '');
      }
    } else {
      await uploadDocumentManually(format, name, file, setPostMsg, username);
      setValue('uploadFile', '');
      setLoader(false);
    }
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const watchUploadFile = watch('uploadFile');

  const handleChange = (filee) => {
    clearErrors();
    setPostMsg('');
    setValue('uploadFile', filee);
  };

  return (
    <div>
      {loader && <Loader open={loader} />}
      {postMsg && (
        <Alert
          severity={postMsg?.severity}
          onClose={() => {
            setPostMsg('');
          }}
          sx={{ margin: '8px', width: '100%' }}
          data-testid="upload file"
        >
          {postMsg?.message}
        </Alert>
      )}

      <Typography variant="h3" component="h3" my={2} className={classes.heading}>
        Upload Bulk Request
      </Typography>
      <Box display="flex" flexDirection="column" gap={1}>
        <CustomFileUpload
          handleChange={handleChange}
          control={control}
          fileTypes={['CSV']}
          setError={setError}
          errors={!!errors?.uploadFile}
          errorText={errors?.uploadFile?.message}
          name="uploadFile"
          fileType="Upload csv files"
          fileTypeErr="Only csv files are allowed"
          label="Upload CSV File"
          uploadedFileName={watchUploadFile?.name}
        />

        <Button
          onClick={handleSubmit(handleUpload)}
          disabled={!watchUploadFile?.name || errors?.uploadFile?.message}
          variant="contained"
          color="secondary"
          id="upload-btn"
        >
          Upload
        </Button>
      </Box>
    </div>
  );
};

export default UploadBulkRequest;
