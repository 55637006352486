import 'Api/customAxios';
import axios from 'axios';
import AuthContext from 'context/AuthContext';
import ErrorPage from 'pages/ErrorPage';
import Logout from 'pages/Logout';
import { useEffect, useMemo, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Route, Routes } from 'react-router-dom';

import Loader from 'components/Loader/Loader';

import { ProtectedRouteOutlet } from '../Routes/ProtectedRoute';
import protectedRoutes from '../Routes/routes';
import LoginFlow from '../pages/LoginFlow/LoginFlow';

const App = () => {
  const [loader, setLoader] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [idToken, setIdToken] = useState('');
  console.log('idToken', idToken);
  const [fullName, setFullName] = useState('');
  const [userID, setUserID] = useState('');
  const [username, setuserName] = useState('');
  const [isUserIdle, setIsUserIdle] = useState(false);
  const authObj = {
    accessToken,
    setAccessToken,
    idToken,
    setIdToken,
    fullName,
    setFullName,
    userID,
    setUserID,
    username,
    setuserName,
    isUserIdle,
    setIsUserIdle,
  };
  const authContextValue = useMemo(
    () => ({
      ...authObj,
    }),
    [{ ...authObj }]
  );

  const handleOnIdle = () => {
    setIsUserIdle(true);
  };

  const { getRemainingTime } = useIdleTimer({
    timeout: 780000,

    onIdle: handleOnIdle,
    events: ['mousemove', 'DOMMouseScroll', 'click', 'keypress', 'submit', 'keydown', 'keyup'],
  });
  console.log(getRemainingTime());

  useEffect(async () => {
    await getTemplateAccessToken();
    await getNotificationAccessToken();
  }, []);

  const getTemplateAccessToken = async () => {
    try {
      setLoader(true);
      const tokenResponse = await axios.post(
        process.env.REACT_APP_COMMON_SERVICE_TOKEN_URL,
        {
          client_id: process.env.REACT_APP_COMMON_SERVICE_CLIENT_ID,
          grant_type: 'client_credentials',
          client_secret: process.env.REACT_APP_COMMON_SERVICE_CLIENT_SECRET,
        },
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
      );

      const { access_token } = tokenResponse?.data;
      sessionStorage.setItem('templateAccessToken', access_token);
    } catch (e) {
      console.log('error generating token', e);
    } finally {
      setLoader(false);
    }
  };

  const getNotificationAccessToken = async () => {
    try {
      setLoader(true);
      const tokenResponse = await axios.post(
        process.env.REACT_APP_NE_SERVICE_TOKEN_URL,
        {
          client_id: process.env.REACT_APP_NE_SERVICE_CLIENT_ID,
          grant_type: 'client_credentials',
          client_secret: process.env.REACT_APP_NE_SERVICE_CLIENT_SECRET,
        },
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
      );

      const { access_token } = tokenResponse?.data;
      sessionStorage.setItem('notificationAccessToken', access_token);
    } catch (e) {
      console.log('error in upload token', e);
    } finally {
      setLoader(false);
    }
  };
  const tokenRefreshTimer = 3540;
  useEffect(() => {
    console.log('intoooo 1');
    const timer = setInterval(async () => {
      await getTemplateAccessToken();
      await getNotificationAccessToken();
    }, tokenRefreshTimer * 1000);
    return () => clearInterval(timer);
  }, []);
  return (
    <AuthContext.Provider value={authContextValue}>
      <Loader open={loader} />
      <Routes>
        <Route exact path="/login" element={<LoginFlow />}></Route>
        <Route exact path="/logout" element={<Logout />}></Route>
        <Route exact path="/saml-error" element={<ErrorPage />}></Route>
        {protectedRoutes.map((x) => (
          <Route key={x.path} path={x.path} exact element={<ProtectedRouteOutlet />}>
            <Route path="" element={x.element} />
          </Route>
        ))}
      </Routes>
    </AuthContext.Provider>
  );
};

export default App;
