import {
  Box,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import classes from 'styles/globalStyle.module.scss';

const CustomRadioButton = (props) => {
  const {
    control,
    label,
    name,
    required,
    radioValues,
    errors,
    errorText,
    displayInline,
    isdisabled,
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field } }) => (
        <Box display="flex" flexDirection="column" gap={0.5} my={0.5}>
          <InputLabel htmlFor={name}>
            {required && <span style={{ color: 'red' }}> *&nbsp;</span>}
            {label}
          </InputLabel>
          <>
            <RadioGroup
              name={name}
              aria-label={label}
              {...field}
              sx={{
                display: 'flex',
                flexDirection: displayInline ? 'row' : 'column',
                gap: '8px',
              }}
            >
              {radioValues.map((x) => {
                return (
                  <FormControlLabel
                    key={x.value}
                    value={x.value}
                    control={
                      <Radio
                        color="secondary"
                        inputProps={{ 'data-testid': x.value, id: x?.label || x?.name }}
                        disabled={isdisabled ? true : false}
                        aria-labelledby={x.name}
                      />
                    }
                    inputRef={ref}
                    label={x.label || x.name}
                    classes={{ label: classes.radioLabel }}
                  />
                );
              })}
            </RadioGroup>
            {errors && (
              <FormHelperText gap={2} className={classes.customSelectError}>
                {' '}
                {errorText}
              </FormHelperText>
            )}
          </>
        </Box>
      )}
    />
  );
};

export default CustomRadioButton;

CustomRadioButton.propTypes = {
  control: PropTypes.instanceOf(Object),
  radioValues: PropTypes.array,
  classes: PropTypes.string,
  name: PropTypes.string,
  dataTestId: PropTypes.string,
  errors: PropTypes.bool,
  isDisabled: PropTypes.bool,
  required: PropTypes.bool,
  displayInline: PropTypes.bool,
  errorText: PropTypes.string,
  label: PropTypes.string,
  isdisabled: PropTypes.bool,
};
CustomRadioButton.defaultProps = {
  control: {},
  classes: '',
  radioValues: [],
  name: '',
  label: '',
  dataTestId: '',
  errors: false,
  isDisabled: false,
  displayInline: false,
  required: false,
  errorText: '',
  isdisabled: false,
};
