/* eslint-disable no-unused-vars */

export const defaultFn = () => {};

export const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    console.log('parsed token', JSON.parse(jsonPayload));
    return JSON.parse(jsonPayload);
  } catch (e) {
    console.log('error in token parsing', e);
    return '';
  }
};

export const getRes = (response) => {
  const { data: resData = {} } = response || {};
  return resData;
};
