// eslint-disable-next-line prettier/prettier
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
// eslint-disable-next-line prettier/prettier
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App/App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import theme from './styles/theme';

if (process.env.REACT_APP_NODE_ENV === 'dev') {
  const { worker } = require('./mocks/browser');
  worker.start();
}

const root = document.getElementById('root');

render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Router>
          <App />
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
  root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
