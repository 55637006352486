/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Button, Grid, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { manageTemplateSchema } from 'schemaValidations/manageTemplateSchema';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomMultilineInput from 'components/CustomMultilineInput/CustomMultilineInput';
import CustomRadioButton from 'components/CustomRadioButton/CustomRadioButton';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';
import Loader from 'components/Loader/Loader';

import TemplateList from './TemplateList';

const ManageTemplate = () => {
  const [loader, setLoader] = useState(false);

  const ApiClient = new ApiClass();
  const [postMsg, setPostMsg] = useState('');
  const [dropDownList, setDropdownList] = useState({});
  const { userID, username, fullName } = useContext(AuthContext);
  console.log({ userID, username, fullName });
  const {
    control,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
    clearErrors,
  } = useForm({
    mode: 'all',
    defaultValues: {
      manageTemplate: '',
      newDomain: '',
      domain: '',
      locale: '',
      templateType: '',
      eventName: '',
      subject: '',
      msgContent: '',
    },
    resolver: yupResolver(manageTemplateSchema),
  });
  const [watchManageTemplate, watchTemplateType] = watch(['manageTemplate', 'templateType']);

  console.log('watch all', watch());
  const createDomain = async (data) => {
    console.log('data', data);
    try {
      setLoader(true);
      const resp = await ApiClient.post(
        `${UrlHelpers.createDomain}`,
        {
          domain: data.newDomain,
          createdBy: username,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        },
        'templateAccessToken'
      );
      setPostMsg({ severity: 'success', message: resp?.message });
      setValue('newDomain', '');
    } catch (e) {
      console.log('e', e);
      setPostMsg({
        severity: 'error',
        message: e?.response?.data?.message || 'Internal server error',
      });
    } finally {
      setLoader(false);
    }
  };

  const manageTemplate = async (data) => {
    console.log('manage temp', data);
    try {
      setLoader(true);
      let payload = {};
      let basicPayload = {
        eventName: data?.eventName,
        templateType: data?.templateType,
        locale: data?.locale,
        domain: data?.domain,
        createdBy: username,
      };
      if (data?.templateType === 'sms') {
        payload = {
          ...basicPayload,
          smsBody: data?.msgContent,
        };
      } else {
        payload = {
          ...basicPayload,
          emailSubject: data?.subject,
          emailBody: data?.msgContent,
        };
      }
      const resp = await ApiClient.post(
        `${UrlHelpers.manageTemplate}`,
        payload,
        {
          headers: { 'Content-Type': 'application/json' },
        },
        'templateAccessToken'
      );
      setPostMsg({ severity: 'success', message: resp?.message });
      resetInputFields();
    } catch (e) {
      console.log('e', e);
      setPostMsg({
        severity: 'error',
        message: e?.response?.data?.message || 'Internal server error',
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(async () => {
    setValue('newDomain', '');
    setValue('domain', '');
    setValue('locale', '');
    setValue('templateType', '');
    setValue('eventName', '');
    setValue('subject', '');
    setValue('msgContent', '');
    setPostMsg('');
    clearErrors();
    if (watchManageTemplate === 'maintain' || watchManageTemplate === 'list') {
      const metaData = await getMetaData();
      setDropdownList(metaData);
    }
  }, [watchManageTemplate]);

  const getMetaData = async () => {
    try {
      setLoader(true);
      return await ApiClient.get(UrlHelpers.getTemplateMetaData, {}, 'templateAccessToken');
    } catch (e) {
      setPostMsg({ severity: 'error', message: 'Error in fetching dropdowns' });
      return '';
    } finally {
      setLoader(false);
    }
  };

  const resetInputFields = () => {
    setValue('eventName', '');
    setValue('subject', '');
    setValue('msgContent', '');
    clearErrors(['eventName', 'subject', 'msgContent']);
  };

  return (
    <Box>
      <Typography variant="h3" component="h3" my={2} className={classes.heading}>
        Manage Template
      </Typography>
      {/* <Box display="flex" flexDirection="column" m={2} alignItems="center"> */}
      <Loader open={loader} />
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomRadioButton
            displayInline
            control={control}
            radioValues={[
              { label: 'Create New Domain', value: 'new' },
              { label: 'Maintain Template', value: 'maintain' },
              { label: 'Template List', value: 'list' },
            ]}
            label="Select one option from the below"
            name="manageTemplate"
            errors={!!errors?.manageTemplate}
            errorText={errors?.manageTemplate?.message}
          />
        </Grid>

        {postMsg && (
          <Alert
            severity={postMsg?.severity}
            onClose={() => {
              setPostMsg('');
            }}
            sx={{ margin: '8px', width: '100%' }}
            data-testid="post-failure-alert"
          >
            {postMsg?.message}
          </Alert>
        )}
        {watchManageTemplate === 'new' && (
          <Box sx={{ width: '100%' }}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <CustomInputBox
                control={control}
                errors={!!errors?.newDomain}
                errorText={errors?.newDomain?.message}
                maxLength="50"
                regex={/^[A-Za-z0-9-]*$/}
                placeholder="Enter domain name"
                label="New Domain"
                name="newDomain"
                required
                flexDirection="column"
              />
            </Grid>
            <Grid container item justifyContent="flex-end">
              <Button variant="contained" color="secondary" onClick={handleSubmit(createDomain)}>
                Save
              </Button>
            </Grid>
          </Box>
        )}
        {watchManageTemplate === 'maintain' && (
          <Grid container justifyContent="space-between" m={0}>
            <Grid item lg={3.5} md={3.5} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                label="Choose Domain"
                options={dropDownList?.domainList?.map((x) => ({ name: x, value: x }))}
                control={control}
                name="domain"
                errors={!!errors?.domain}
                errorText={errors?.domain?.message}
                required
                displayEmpty
              />
            </Grid>
            <Grid item lg={3.5} md={3.5} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                label="Choose Locale"
                options={dropDownList?.localeList?.map((x) => ({
                  name: x?.displayValue,
                  value: x?.actualValue,
                }))}
                control={control}
                name="locale"
                errors={!!errors?.locale}
                errorText={errors?.locale?.message}
                required
                displayEmpty
              />
            </Grid>
            <Grid item lg={3.5} md={3.5} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                label="Choose Template Type"
                options={dropDownList?.templateTypeList?.map((x) => ({
                  name: x?.displayValue,
                  value: x?.actualValue,
                }))}
                control={control}
                name="templateType"
                errors={!!errors?.templateType}
                errorText={errors?.templateType?.message}
                required
                displayEmpty
                isValue
                valueChange={resetInputFields}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <CustomInputBox
                control={control}
                errors={!!errors?.eventName}
                errorText={errors?.eventName?.message}
                maxLength="50"
                regex={/^[A-Za-z0-9-]*$/}
                placeholder="Enter Event Name"
                label="Event Name"
                name="eventName"
                flexDirection="column"
                required
              />
            </Grid>
            {watchTemplateType === 'email' && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CustomInputBox
                  control={control}
                  errors={!!errors?.subject}
                  errorText={errors?.subject?.message}
                  maxLength="200"
                  placeholder="Enter Subject"
                  label="Subject"
                  name="subject"
                  required
                  flexDirection="column"
                />
              </Grid>
            )}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <CustomMultilineInput
                control={control}
                errors={!!errors?.msgContent}
                errorText={errors?.msgContent?.message}
                placeholder="Enter message content"
                label={watchTemplateType === 'email' ? 'Mail Body' : 'Message'}
                name="msgContent"
                required
                flexDirection="column"
              />
            </Grid>
            <Grid container item justifyContent="flex-end">
              <Button variant="contained" color="secondary" onClick={handleSubmit(manageTemplate)}>
                Save
              </Button>
            </Grid>
          </Grid>
        )}
        {watchManageTemplate === 'list' && <TemplateList dropDownList={dropDownList} />}
      </Grid>
      {/* </Box> */}
    </Box>
  );
};

export default ManageTemplate;
