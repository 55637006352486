import { Box, Button, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import Caution from 'assets/svg/Caution.svg';
import ClockImage from 'assets/svg/ClockImage.svg';
import AuthContext from 'context/AuthContext';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BootstrapDialogTitle from 'components/CustomDialogBox/CustomDialogBox';

const SessionTimeoutDialog = () => {
  const navigate = useNavigate();
  const { isUserIdle, setIsUserIdle, setAccessToken } = useContext(AuthContext);
  const [counter, setCounter] = useState(120);

  const handleClose = () => {
    setIsUserIdle(false);
    setCounter(120);
  };

  const padTime = (time) => {
    return String(time).length === 1 ? `0${time}` : `${time}`;
  };

  const format = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return `${minutes}:${padTime(seconds)}`;
  };

  useEffect(async () => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter((c) => c - 1), 1000);
    } else {
      await handleCounterTimeout();
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [counter]);

  const handleCounterTimeout = async () => {
    setAccessToken('');
    navigate('/logout');
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isUserIdle}
      PaperProps={{ sx: { padding: '30px 10px', maxWidth: '700px' } }}
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid container justifyContent="space-between">
          <Grid
            item
            lg={5}
            md={5}
            sm={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box display="flex">
              <img src={ClockImage} style={{ width: '100%' }} />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12}>
            <BootstrapDialogTitle
              display="flex"
              alignItems="center"
              onClose={handleClose}
              sx={{ gap: '5px', padding: '0' }}
            >
              <img src={Caution} />
              <Typography variant="h1">Session Timeout</Typography>
            </BootstrapDialogTitle>
            <Typography variant="subtitle1" sx={{ padding: '16px 0' }}>
              You will be logged out due to inactivity in
            </Typography>
            <Box display="flex" alignItems="baseline" sx={{ gap: '8px' }}>
              <Typography fontSize="40px !important" color="#E37617" fontWeight={700}>
                {format(counter)}
              </Typography>
              <Typography variant="subtitle1" fontWeight={700}>
                {counter < 60 ? 'seconds' : 'minutes'}
              </Typography>
            </Box>
            <Typography variant="subtitle1" sx={{ padding: '16px 0' }}>
              Press OK to prolong the session.
            </Typography>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              Ok
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SessionTimeoutDialog;
