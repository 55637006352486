import AssignmentIcon from '@mui/icons-material/Assignment';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HomeIcon from '@mui/icons-material/Home';
import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import AuthContext from 'context/AuthContext';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import SessionTimeoutDialog from 'components/SessionTimeoutDialog';
import SkipLink from 'components/SkipLink';

import classes from '../../styles/globalStyle.module.scss';

const openedMixin = (theme) => ({
  [theme.breakpoints.up('md')]: {
    width: '20%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '10%',
  },

  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflow: 'hidden',
  width: `calc(${theme.spacing(12)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  [theme.breakpoints.up('md')]: {
    width: '20%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '10%',
  },
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function SideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  console.log('pathname location', location.pathname);
  let { accessToken, setAccessToken, idToken, setIdToken, isUserIdle } = useContext(AuthContext);
  const [didMount, setDidMount] = useState(false);
  const sideMenuList = ['Home', 'Manage Template', 'Upload Bulk Request'];

  useEffect(() => {
    bankerUserCheck();
    setDidMount(true);
  }, []);

  const bankerUserCheck = () => {
    const hash = window?.location?.hash;

    if (hash && hash.indexOf('error_description') === -1) {
      accessToken = hash.split('&')[0]?.split('=')[1];
      idToken = hash.split('&')[1]?.split('=')[1];
      setAccessToken(accessToken);
      setIdToken(idToken);

      navigate('/home');
    } else if (hash && hash.indexOf('error_description') > -1) {
      navigate('/saml-error');
    } else if (!accessToken) {
      navigate('/login');
    }
  };

  useEffect(() => {
    const navigateMapping = {
      '/home': 'Home',
      '/manage-template': 'Manage Template',
      '/upload-bulk': 'Upload Bulk Request',
    };
    let activeIndex = sideMenuList.findIndex((x) => x === navigateMapping?.[location.pathname]);
    setSelectedIndex(activeIndex);
  }, [location.pathname]);

  const handleNavigate = (text) => {
    switch (text) {
      case 'Home': {
        navigate('/home');
        break;
      }

      case 'Manage Template':
        navigate('/manage-template');
        break;
      case 'Upload Bulk Request':
        navigate('/upload-bulk');
        break;

      default:
        break;
    }
  };
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = async (_event, text) => {
    handleNavigate(text);
  };

  const handleIcons = (index) => {
    switch (index) {
      case 'Home':
        return <HomeIcon />;
      case 'Manage Template':
        return <AssignmentIcon />;
      case 'Upload Bulk Request':
        return <CloudUploadIcon />;

      default:
        break;
    }
  };

  return (
    <>
      <SkipLink />

      {didMount && (
        <Box container sx={{ display: 'flex', width: '100%' }}>
          <CssBaseline />

          <Drawer
            variant="permanent"
            open={false}
            PaperProps={{
              sx: {
                backgroundColor: '#F28023',
                color: 'white',
              },
            }}
            sx={{ whiteSpace: 'break-spaces' }}
          >
            <Divider />
            <List>
              {sideMenuList.map((text, index) => (
                <ListItem key={text} disablePadding sx={{ display: 'block', margin: '15px 0' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: 'center',
                      px: 2.5,
                    }}
                    className={classes.listItemBtnOpen}
                    // disabled={text === 'Reports'}
                    selected={selectedIndex === index}
                    onClick={(event) => handleListItemClick(event, text)}
                    id={`${text}-menu`}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 'auto',
                        justifyContent: 'center',
                        color: 'white',
                      }}
                    >
                      {handleIcons(text)}
                    </ListItemIcon>

                    <ListItemText
                      primary={text}
                      primaryTypographyProps={{ fontSize: '0.85rem', fontWight: 700 }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Drawer>
          {isUserIdle && <SessionTimeoutDialog />}
          <Box component="main" flexGrow="1" sx={{ width: { lg: '90%', md: '80%' } }}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              className={classes.contentContainer}
              id="main-content"
            >
              <Paper elevation={0} className={classes.paperBg}>
                <Outlet />
              </Paper>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
