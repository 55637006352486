import ApiClass from 'Api/ApiClient';
import axios from 'axios';
import SparkMD5 from 'spark-md5';
import { UrlHelpers } from 'urlHelpers/urlHelper';

const ApiClient = new ApiClass();

export const uploadDocumentManually = async (format, fileName, file, setPostMsg, username) => {
  let response = null;
  const formData = new FormData();
  formData.append('file', file);

  try {
    response = await ApiClient.post(
      `${UrlHelpers.uploadcsv}?name=${fileName}&createdBy=${username}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      'notificationAccessToken'
    );
    console.log('response', response);
    setPostMsg({ severity: 'success', message: response?.message });
  } catch (e) {
    setPostMsg({
      severity: 'error',
      message: e?.response?.data?.message || 'Internal server error',
    });

    return response;
  }
  return response;
};

export const uploadXlDocumentManually = async (request, setLoader, setPostMsg) => {
  let response = null;
  try {
    response = await ApiClient.post(UrlHelpers.uploadcsvxl, request, {
      headers: {
        'Content-Type': 'application/json',
      },
    },
    'notificationAccessToken');
  } catch (e) {
    setLoader(false);
    setPostMsg({
      severity: 'error',
      message: e?.response?.data?.message || 'Internal server error',
    });

    return response;
  }
  setLoader(false);

  return response;
};

export const uploadDocumentByPreSignedURL = async (
  preSignedUrl,
  file,
  fileMd5Base64,
  setLoader,
  setPostMsg
) => {
  let response = null;
  try {
    response = await axios.put(preSignedUrl, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Content-MD5': fileMd5Base64,
      },
    });
    setPostMsg({ severity: 'success', message: 'File uploaded successfully' });
  } catch (e) {
    setLoader(false);
    setPostMsg({
      severity: 'error',
      message: e?.response?.data?.message || 'Internal server error',
    });

    return response;
  }

  setLoader(false);
  return response;
};

export const generateFileMD5 = (file) =>
  new Promise((resolve, reject) => {
    const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
    const chunkSize = 2097152;
    const chunks = Math.ceil(file.size / chunkSize);

    let currentChunk = 0;
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      spark.append(e.target.result);
      currentChunk += 1;

      if (currentChunk < chunks) {
        loadNext();
      } else {
        const hash = spark.end();
        resolve(hash);
      }
    };

    fileReader.onerror = () => {
      reject();
    };

    function loadNext() {
      const start = currentChunk * chunkSize;
      const end = start + chunkSize >= file.size ? file.size : start + chunkSize;
      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    }
    loadNext();
  });
