import axios from 'axios';

const customAxios = (tokenFlag) => {
  const axiosInstance = axios.create();

  const accessToken = sessionStorage.getItem(tokenFlag);

  // request interceptor for adding token
  axiosInstance.interceptors.request.use((config) => {
    const configg = config;

    configg.headers['Authorization'] = `Bearer ${accessToken}`;

    return config;
  });

  return axiosInstance;
};

export default customAxios;
