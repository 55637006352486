import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';

const Logout = () => {
  useEffect(() => {
    window.history.pushState(null, null, '');
    window.addEventListener('popstate', (event) => {
      window.history.pushState(null, null, '');
      console.log(`location: ${document.location}, state: ${JSON.stringify(event.state)}`);
    });
  }, []);
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
      <Typography variant="subtitle2" component="p">
        You are logged out due to inactivity. Please <a href="/login"> Sign in </a>to continue
      </Typography>
    </Box>
  );
};

export default Logout;
