import { Box, Link, Typography } from '@mui/material';

const ErrorPage = () => {
  const cognitoConfig = JSON.parse(process.env.REACT_APP_CONFIG);
  const loginUrl =
    'https://' +
    cognitoConfig.DOMAIN +
    '/oauth2/authorize?response_type=' +
    cognitoConfig.RESPONSETYPE +
    '&client_id=' +
    cognitoConfig.APP_CLIENT_ID +
    '&redirect_uri=' +
    cognitoConfig.REDIRECTSIGNIN;
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h3">Invalid Saml Response</Typography>
      <Link href={loginUrl}>Re-login</Link>
    </Box>
  );
};

export default ErrorPage;
