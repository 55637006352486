const endPoint = process.env.REACT_APP_BASE_END_POINT;
export const UrlHelpers = {
  createDomain: `${endPoint}/app/template-engine/create-domain`,
  getTemplateMetaData: `${endPoint}/app/template-engine/get-template-metadata`,
  getTemplateList: `${endPoint}/app/template-engine/get-template-list`,
  fetchPresignedurl: `${endPoint}/app/template-engine/fetch-presigned-url`,
  manageTemplate: `${endPoint}/app/template-engine/create-template-json`,
  uploadcsv: `${endPoint}/dnotificationapi/upload`,
  uploadcsvxl: `${endPoint}/dnotificationapi/uploadxl`,
};
