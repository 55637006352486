import Home from 'pages/Home/Home';
import ManageTemplate from 'pages/ManageTemplate/ManageTemplate';
import UploadBulkRequest from 'pages/UploadBulkRequest/UploadBulkRequest';

const protectedRoutes = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/home',
    element: <Home />,
  },
  {
    path: '/upload-bulk',
    element: <UploadBulkRequest />,
  },
  {
    path: '/manage-template',
    element: <ManageTemplate />,
  },
];

export default protectedRoutes;
