import { Pagination } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const TablePagination = ({ postsPerPage, totalPosts, paginate, page }) => {
  const countOfPages = Math.ceil(totalPosts / postsPerPage);

  return <Pagination count={countOfPages} page={+page} onChange={paginate} />;
};

export default TablePagination;

TablePagination.propTypes = {
  postsPerPage: PropTypes.number,
  totalPosts: PropTypes.number,
  paginate: PropTypes.func,
  page: PropTypes.number,
};

TablePagination.defaultProps = {
  postsPerPage: PropTypes.number,
  totalPosts: PropTypes.number,
  paginate: PropTypes.func,
  page: 1,
};
