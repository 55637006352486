/* eslint-disable react/prop-types */
import { Box, Grid, Link, Paper, TableHead, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ApiClass from 'Api/ApiClient';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import ErrorAlert from 'components/ErrorAlert';
import Loader from 'components/Loader/Loader';
import TablePagination from 'components/TablePagination';

import SearchTemplate from './SearchTemplate';

export function TableCellTypography({ children }) {
  return (
    <Typography variant="subtitle2" component="p">
      {children}
    </Typography>
  );
}

TableCellTypography.propTypes = {
  children: PropTypes.node,
};

TableCellTypography.defaultProps = {
  children: <></>,
};

export function TableHeaderTypography({ children }) {
  return (
    <Typography variant="subtitle2" component="p" sx={{ fontWeight: 700 }}>
      {children}
    </Typography>
  );
}

TableHeaderTypography.propTypes = {
  children: PropTypes.node,
};

TableHeaderTypography.defaultProps = {
  children: <></>,
};

const TemplateList = ({ dropDownList = [] }) => {
  const ApiClient = new ApiClass();
  const [loader, setLoader] = useState(false);
  const [apiErr, setApiErr] = useState('');
  const [templateList, setTemplateList] = useState([]);
  const [fullTemplateList, setFullTemplateList] = useState([]);
  const [page, setPage] = useState(1);
  let rowsPerPage = 10;
  const paginate = (_, pageNumber) => {
    setPage(pageNumber);
  };
  const indexOfLastPost = page * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;

  const calcUserList = (arr) => {
    return Array.isArray(arr) ? arr?.slice(indexOfFirstPost, indexOfLastPost) : [];
  };

  useEffect(async () => {
    try {
      setLoader(true);
      const resp = await ApiClient.get(UrlHelpers.getTemplateList, {}, 'templateAccessToken');
      setTemplateList(resp);
      setFullTemplateList(resp);
    } catch (e) {
      setApiErr(e?.response?.data?.message || 'Error fetching template list');
    } finally {
      setLoader(false);
    }
  }, []);

  const onAction = async (fileKey) => {
    console.log('fileKey', fileKey);
    try {
      setLoader(true);
      const resp = await ApiClient.post(
        UrlHelpers.fetchPresignedurl,
        {
          fileKey: fileKey,
        },
        {},
        'templateAccessToken'
      );

      downloadFile(resp?.preSignedUrl, fileKey);
    } catch (e) {
      console.log('e', e);
      setApiErr(e?.response?.data?.message || 'Error in download');
    } finally {
      setLoader(false);
    }
  };

  const downloadFile = async (url, fileKey) => {
    let filename = fileKey.split('/');
    filename = filename?.[filename?.length - 1] || 'sample';
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(console.error);
  };

  const TableHeading = ['Type', 'Domain', 'Event', 'Locale', 'Action'];

  return (
    <>
      <Loader open={loader} />
      <ErrorAlert apiErr={apiErr} setApiErr={setApiErr} />
      <Grid
        container
        sx={{
          overflowX: 'auto',
          width: '100%',
        }}
      >
        <SearchTemplate
          fullTemplateList={fullTemplateList}
          templateList={templateList}
          setTemplateList={setTemplateList}
          dropDownList={dropDownList}
          setPage={setPage}
        />

        <Paper sx={{ width: '100%', boxShadow: 'none', border: '1px solid rgb(242, 242, 242)' }}>
          <Typography variant="subtitle2" color="secondary" fontWeight="700" m={1}>
            {templateList?.length > 0 &&
              `Showing ${indexOfFirstPost + 1} to ${
                templateList?.length > 9 ? indexOfLastPost : templateList?.length
              } of ${templateList?.length} entries`}
          </Typography>
          <Table aria-label="applications-table">
            <TableHead>
              <TableRow
                sx={{ backgroundColor: '#F2F2F2' }}
                className={classes.tableHeadingApplications}
              >
                {TableHeading?.map((x) => {
                  return (
                    <TableCell key={x}>
                      <TableHeaderTypography>{x}</TableHeaderTypography>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {templateList?.length > 0 &&
                calcUserList(templateList)?.map((row) => (
                  <TableRow
                    key={row?.fileKey}
                    className={classes.tableHeadingApplications}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>
                      <TableCellTypography>{row?.displayTemplateType}</TableCellTypography>
                    </TableCell>

                    <TableCell width="200">
                      <TableCellTypography display="flex" alignItems="center">
                        {row?.domain}
                      </TableCellTypography>
                    </TableCell>

                    <TableCell>
                      <TableCellTypography>{row?.event}</TableCellTypography>
                    </TableCell>
                    <TableCell>
                      <TableCellTypography>{row?.displayLocale}</TableCellTypography>
                    </TableCell>
                    <TableCell>
                      <TableCellTypography>
                        <Link
                          component="button"
                          variant="subtitle2"
                          color="secondary"
                          onClick={() => onAction(row?.fileKey)}
                        >
                          Download
                        </Link>
                      </TableCellTypography>
                    </TableCell>
                  </TableRow>
                ))}

              {templateList?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={12} sx={{ fontSize: '14px !important', textAlign: 'center' }}>
                    No records found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Box sx={{ margin: '15px' }}>
        <TablePagination
          postsPerPage={10}
          totalPosts={templateList?.length}
          paginate={paginate}
          page={page}
        />
      </Box>
    </>
  );
};

export default TemplateList;
