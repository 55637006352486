const OrientalSSO = () => {
  console.log('orientalsso');

  const cognitoConfig = JSON.parse(process.env.REACT_APP_CONFIG);
  console.log('cognito config', cognitoConfig);

  const loginUrl =
    'https://' +
    cognitoConfig.DOMAIN +
    '/oauth2/authorize?response_type=' +
    cognitoConfig.RESPONSETYPE +
    '&client_id=' +
    cognitoConfig.APP_CLIENT_ID +
    '&redirect_uri=' +
    cognitoConfig.REDIRECTSIGNIN;

  window.location.href = loginUrl;

  return null;
};

export default OrientalSSO;
