/* eslint-disable react/prop-types */
import { Button, Grid, Link } from '@mui/material';
import { useForm } from 'react-hook-form';

import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';

const SearchTemplate = ({ setTemplateList, fullTemplateList, dropDownList,setPage }) => {
  const eventList = [...new Set(fullTemplateList.map((x) => x.event))];
  console.log('dropDownList', dropDownList, eventList);
  const {
    control,
    reset,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'all',
    defaultValues: {
      domain: '',
      locale: '',
      templateType: '',
      eventName: '',
    },
    //   resolver: yupResolver(manageTemplateSchema),
  });

  const [watchdomain, watchlocale, watchtemplateType, watcheventName] = watch([
    'domain',
    'locale',
    'templateType',
    'eventName',
  ]);
  const searchTemplate = (data) => {
    const { domain, locale, templateType, eventName } = data;
    let filteredList = [...fullTemplateList];
    if (domain) {
      filteredList = filteredList.filter((x) => x.domain === domain);
    }
    if (locale) {
      filteredList = filteredList.filter((x) => x.locale === locale);
    }
    if (templateType) {
      filteredList = filteredList.filter((x) => x.templateType === templateType);
    }
    if (eventName) {
      filteredList = filteredList.filter((x) => x.event === eventName);
    }
    setTemplateList(filteredList);
    setPage(1)
  };
  return (
    <Grid container gap={1} m={0}>
      <Grid item lg={2} md={2} sm={12} xs={12}>
        <CustomSelectBox
          flexDirection="column"
          placeHolder="Domain"
          options={dropDownList?.domainList?.map((x) => ({ name: x, value: x }))}
          control={control}
          name="domain"
          errors={!!errors?.domain}
          errorText={errors?.domain?.message}
          displayEmpty
        />
      </Grid>
      <Grid item lg={2} md={2} sm={12} xs={12}>
        <CustomSelectBox
          flexDirection="column"
          placeHolder="Locale"
          options={dropDownList?.localeList?.map((x) => ({
            name: x?.displayValue,
            value: x?.actualValue,
          }))}
          control={control}
          name="locale"
          errors={!!errors?.locale}
          errorText={errors?.locale?.message}
          displayEmpty
        />
      </Grid>
      <Grid item lg={2} md={2} sm={12} xs={12}>
        <CustomSelectBox
          flexDirection="column"
          placeHolder="Template Type"
          options={dropDownList?.templateTypeList?.map((x) => ({
            name: x?.displayValue,
            value: x?.actualValue,
          }))}
          control={control}
          name="templateType"
          errors={!!errors?.templateType}
          errorText={errors?.templateType?.message}
          displayEmpty
        />
      </Grid>
      <Grid item lg={2} md={2} sm={12} xs={12}>
        <CustomSelectBox
          flexDirection="column"
          placeHolder="Event Name"
          options={eventList?.map((x) => ({
            name: x,
            value: x,
          }))}
          control={control}
          name="eventName"
          errors={!!errors?.eventName}
          errorText={errors?.eventName?.message}
          displayEmpty
        />
      </Grid>
      <Grid container item lg={2} gap={1} md={2} sm={12} xs={12} alignItems="center">
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSubmit(searchTemplate)}
          disabled={!watchdomain && !watchlocale && !watchtemplateType && !watcheventName}
        >
          Apply
        </Button>
        <Link
          component="button"
          variant="body2"
          color="secondary"
          onClick={() => {
            reset();
            setTemplateList(fullTemplateList);
          }}
        >
          Clear
        </Link>
      </Grid>
    
    </Grid>
  );
};

export default SearchTemplate;
