import * as yup from 'yup';

export const manageTemplateSchema = yup.object().shape({
  newDomain: yup.string().when('manageTemplate', {
    is: 'new',
    then: yup
      .string()
      .required('* Mandatory field')
      .required('* Mandatory field')
      .test('len', 'Maximum 50 characters can be entered', (val) => val?.length <= 50)
      .matches(/^[A-Za-z0-9-]*$/, 'Domain name cannot contain special characters except -'),
  }),
  domain: yup.string().when('manageTemplate', {
    is: 'maintain',
    then: yup.string().required('* Mandatory field'),
  }),
  locale: yup.string().when('manageTemplate', {
    is: 'maintain',
    then: yup.string().required('* Mandatory field'),
  }),
  templateType: yup.string().when('manageTemplate', {
    is: 'maintain',
    then: yup.string().required('* Mandatory field'),
  }),
  eventName: yup.string().when('manageTemplate', {
    is: 'maintain',
    then: yup
      .string()
      .required('* Mandatory field')
      .matches(/^[A-Za-z0-9-]*$/, 'Event name cannot contain special characters except -'),
  }),

  subject: yup.string().when('templateType', {
    is: 'email',
    then: yup.string().required('* Mandatory field'),
  }),
  msgContent: yup.string().when('manageTemplate', {
    is: 'maintain',
    then: yup.string().required('* Mandatory field'),
  }),
});
