import { Alert, IconButton } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { defaultFn } from 'utils/utility';

const ErrorAlert = ({ apiErr, setApiErr, success, details }) => {
  const tabRef = useRef(null);
  useEffect(() => {
    if (apiErr) {
      tabRef.current.focus();
    }
  }, [apiErr]);

  console.log('details?.dealers?.length ', details?.dealers);

  return (
    <>
      {!!apiErr && (
        <Alert
          sx={{
            marginTop: '8px',
            alignItems: 'center',
            fontSize: '14px !important',
          }}
          aria-label={apiErr}
          severity={success ? 'success' : 'error'}
          action={
            <IconButton
              ref={tabRef}
              aria-label={`${apiErr}-close`}
              color="inherit"
              size="small"
              data-testid="close-btn"
              onClick={() => {
                setApiErr('');
              }}
            >
              <GridCloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {typeof apiErr !== 'object' ? apiErr : apiErr?.message || apiErr?.Message}
        </Alert>
      )}
    </>
  );
};

export default ErrorAlert;

ErrorAlert.propTypes = {
  apiErr: PropTypes.string,
  setApiErr: PropTypes.func,
  success: PropTypes.bool,
  details: PropTypes.instanceOf(Object),
};
ErrorAlert.defaultProps = {
  apiErr: '',
  setApiErr: defaultFn,
  success: false,
  details: {},
};
