import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    minWidth: 'clamp(400px, 50vw, 800px)',
    padding: '16px',
    minHeight: 'clamp(450px,60vh,100%)',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, divider, ariaLabel, ...other } = props;

  return (
    <>
      <DialogTitle
        aria-label={ariaLabel || children[0]?.props?.children}
        sx={{ m: 0, p: 2 }}
        {...other}
      >
        {children}

        {divider && <Divider sx={{ color: 'primary' }} />}
      </DialogTitle>
      <Button
        aria-label="close-button"
        data-testid="close_testid"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
          height: 'auto',
          width: 'auto',
          minWidth: 'auto',
          minHeight: 'auto',
        }}
      >
        <CloseIcon />
      </Button>
    </>
  );
};

export default BootstrapDialogTitle;

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  divider: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

BootstrapDialogTitle.defaultProps = {
  children: <></>,
  divider: false,
  ariaLabel: '',
};
