import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Button, InputLabel, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { FileUploader } from 'react-drag-drop-files';
import { Controller } from 'react-hook-form';
import classes from 'styles/globalStyle.module.scss';
import { defaultFn } from 'utils/utility';

const CustomFileUpload = ({
  control,
  name,
  handleChange,
  fileTypes,
  setError,
  fileTypeErr,
  fileType,
  label,
  isMultiple,
  errors,
  uploadedFileName,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box display="flex" flexDirection="column" gap={0.625}>
          {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
          <FileUploader
            handleChange={handleChange}
            inputProps={{
              'data-testid': name,
            }}
            name="file"
            types={fileTypes}
            multiple={isMultiple}
            onTypeError={() => {
              setError(name, {
                type: 'custom',
                message: fileTypeErr
                  ? fileTypeErr
                  : 'Only files with the following formats are allowed: PNG, JPG, PDF. Click to upload again',
              });
            }}
            {...field}
          >
            <Box
              className={classes.uploadContainer}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              sx={{ background: 'transparent' }}
            >
              <Typography variant="subtitle2" component="p">
                Drag and drop your file here
              </Typography>
              <Typography variant="subtitle2" component="p" sx={{ padding: '20px 0' }}>
                or
              </Typography>
              <Box
                sx={{
                  display: 'block',
                }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  display="flex"
                  sx={{
                    borderRadius: '4px',
                  }}
                  id="upload-btn"
                >
                  <UploadFileIcon />
                  &nbsp;
                  <Typography
                    variant="subtitle1"
                    component="p"
                    sx={{ fontWeight: '700' }}
                  ></Typography>
                  Browse
                </Button>
              </Box>

              <Typography
                component="p"
                sx={{ color: '#909090', fontSize: '10px', marginTop: '20px' }}
              >
                {fileType}
              </Typography>
              {errors && (
                <Typography color="red" data-testid="fileErr">
                  {fileTypeErr}
                </Typography>
              )}
              {!errors && (
                <Typography color="green" data-testid="filename">
                  {uploadedFileName}
                </Typography>
              )}
            </Box>
          </FileUploader>
        </Box>
      )}
    />
  );
};

export default CustomFileUpload;

CustomFileUpload.propTypes = {
  control: PropTypes.instanceOf(Object),
  name: PropTypes.string,
  handleChange: PropTypes.func,
  fileTypes: PropTypes.array,
  fileErr: PropTypes.string,
  setFileErr: PropTypes.func,
  errors: PropTypes.bool,
  errorText: PropTypes.string,
  flow: PropTypes.string,
  setError: PropTypes.func,
  setShowCamera: PropTypes.func,
  fileTypeErr: PropTypes.string,
  fileType: PropTypes.string,
  label: PropTypes.string,
  isMultiple: PropTypes.bool,
  uploadedFileName: PropTypes.string,
};
CustomFileUpload.defaultProps = {
  control: {},
  name: '',
  handleChange: defaultFn,
  fileTypes: [],
  fileErr: '',
  setFileErr: defaultFn,
  errors: false,
  errorText: '',
  setError: defaultFn,
  fileTypeErr: '',
  fileType: 'Max: 10MB supporting file types: PNG, JPG, PDF.',
  label: '',
  isMultiple: false,
  uploadedFileName: '',
};
