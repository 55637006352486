import { Backdrop, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

const Loader = ({ open }) => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
      <CircularProgress data-testid="loader" style={{ color: 'primary' }} />
    </Backdrop>
  );
};
export default Loader;
Loader.propTypes = {
  open: PropTypes.bool,
};
Loader.defaultProps = {
  open: false,
};
