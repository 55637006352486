import { createTheme } from '@mui/material/styles';
import { h1Bold } from 'styles/variables';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#F28023',
    },
    secondary: {
      main: '#661c69',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    h1: {
      ...h1Bold,
      fontWeight: '700 !important',
      fontSize: '32px !important',
      '@media(max-width: 899px)': {
        fontSize: '1.5rem !important',
      },
    },
    h2: {
      ...h1Bold,
      fontWeight: '700 !important',
      fontSize: '28px !important',
    },
    h3: {
      ...h1Bold,
      fontWeight: '700 !important',
      fontSize: '24px !important',
    },
    h4: {
      ...h1Bold,
      fontWeight: '700 !important',
      fontSize: '22px !important',
    },
    h5: {
      ...h1Bold,
      fontSize: '20px !important',
    },
    h6: {
      ...h1Bold,
      fontSize: '18px !important',
      '@media(max-width: 899px)': {
        fontSize: '16px !important',
      },
    },
    subtitle1: {
      ...h1Bold,
      fontSize: '16px !important',
    },
    subtitle2: {
      ...h1Bold,
      fontSize: '14px !important',
    },
    body1: {
      ...h1Bold,
      fontSize: '12px !important',
    },
    body2: {
      ...h1Bold,
      fontSize: '11px !important',
    },

    // h2: {
    //   fontSize: '1.5rem',
    //   fontWeight: 600,
    //   '@media(max-width: 899px)': {
    //     fontSize: '1rem',
    //   },
    // },
    // h3: {
    //   fontSize: '1rem',
    //   fontWeight: 600,
    // },
    // h4: {
    //   fontSize: '14px',
    //   fontWeight: 400,
    //   '@media(max-width: 899px)': {
    //     fontSize: '12px',
    //   },
    // },
    // p: {
    //   fontSize: '1rem',
    //   '@media(max-width: 899px)': {
    //     fontSize: '0.75rem',
    //   },
    // },
    // label: {
    //   fontWeight: 600,
    //   fontSize: '1rem',
    //   color: 'rgba(0, 0, 0, 0.6)',
    //   '@media(max-width: 899px)': {
    //     fontSize: '0.75rem',
    //   },
    // },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          width: '100%',
          height: '40px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          width: '100%',
          fontWeight: 600,
          whiteSpace: 'normal',
          '@media(max-width: 899px)': {
            fontSize: '0.75rem',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          width: 'max-content',
          marginRight: '0',
        },
        label: {
          // width: '100%',
          fontWeight: '400',
          fontSize: '14px !important',
          whiteSpace: 'normal',
          '@media(max-width: 899px)': {
            fontSize: '0.75rem',
          },
          width: 'max-content',
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          whiteSpace: 'normal',
          color: '#000',
          fontFamily: 'Arial',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '14px !important',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          padding: '2px 4px 7.5px 6px !important',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...h1Bold,
          fontSize: '16px',
          minWidth: '90px',
          height: '40px',
          fontWeight: 700,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        outlinedInfo: {
          backgroundColor: '#F1F6FD',
          border: 'none',
          borderLeft: '5px solid #0052EA',
          borderRadius: '0',
          color: '#000000',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&[disabled]': {
            color: 'grey',
            cursor: 'default',
            '&:hover': {
              textDecoration: 'none',
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#661c69',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#661c69',
          '&.Mui-selected': {
            color: '#444444',
            fontWeight: 700,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: '1px solid #661D69',
          borderRadius: '5px',
          color: '#661D69',

          '&:hover': {
            backgroundColor: 'rgb(102, 29, 105)',
            color: '#fff',
          },
          '&:focus': {
            border: '1px solid #661D69',
            borderRadius: '5px',
            color: '#661D69',
          },
          '&.Mui-selected': {
            backgroundColor: 'rgb(102, 29, 105)',
            color: '#fff',
            '&:hover': {
              backgroundColor: 'rgb(102, 29, 105)',
              color: '#fff',
            },
          },
          '&.Mui-disabled': {
            border: '1px solid #661D69',
            color: '#661D69',
            opacity: 0.4,
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
        grouped: {
          maxHeight: '40px',

          '&:disabled': {
            border: '1px solid #661D69',
            opacity: 0.4,
          },
        },
      },
    },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(255,255,255,.1)',
            borderLeft: '5px solid #fff',
          },
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFD68A',
          fontWeight: 600,
        },
      },
    },
  },
});

export default theme;
