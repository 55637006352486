import { Grid, Typography } from '@mui/material';
import AuthContext from 'context/AuthContext';
import { useContext, useEffect } from 'react';
import { parseJwt } from 'utils';

const Home = () => {
  let { idToken, setFullName, setUserID, username, setuserName } = useContext(AuthContext);

  useEffect(async () => {
    const parsedToken = parseJwt(idToken);
    console.log('parsed id token', parsedToken);
    let { name, given_name, family_name } = parsedToken;
    const userId = parsedToken?.['cognito:username'];

    if (name) {
      setuserName(name);
    }
    if (given_name && family_name) {
      setFullName(`${given_name} ${family_name}`);
    }
    if (userId) {
      setUserID(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h4">
        Welcome,
        <Typography variant="span" color="primary">
          {username}
        </Typography>
      </Typography>
    </Grid>
  );
};

export default Home;
